'use client'

import classnames from 'classnames'
import LogoImg from 'assets/images/logo_250x64.png'
import { Container } from 'atoms'
import { useTranslation } from 'i18n/client'
import Image from 'next/image'
import Link from 'next/link'
import { FC, ReactNode, useMemo } from 'react'
import { MdAccountCircle } from 'react-icons/md'

import { useSignedIn } from '@vayapin/oauth-kit-react'

import ButtonBack from './ButtonBack'
import ButtonMenu from './ButtonMenu'
import Styles from './NavigationBar.module.sass'

const LOGO_RATIO = 250 / 64
const LOGO_SIZE = {
  height: 25,
  width: 25 * LOGO_RATIO
}

export interface NavigationBarProps {
  skipBack?: boolean,
  skipMenu?: boolean,
  skipLogo?: boolean,
  skipBackground?: boolean,
  title?: ReactNode;
  backHref?: string;
}

const NavigationBar: FC<NavigationBarProps> = ({
  skipMenu = false,
  skipBack = false,
  skipLogo = false,
  skipBackground = false,
  title,
  backHref = '/',
}) => {
  const { t } = useTranslation()
  const signedIn = useSignedIn()

  const headerClasses = useMemo(() => classnames({
    [Styles.header]: true,
    [Styles.headerBackground]: !skipBackground,
  }), [skipBackground])

  return (
    <header className={Styles.wrapper}>
      <div className={headerClasses}>
        <Container className={Styles.container}>
          <div className={`d-flex d-md-none ${Styles.colIcon} ${Styles.colIconLeft}`}>
            {!skipBack && <ButtonBack href={backHref} />}
          </div>
          {!skipLogo && (
            <Link href="/" id="navigation-home-link" className={`d-none d-md-block ${Styles.colDesktopLogo}`}>
              <Image
                src={LogoImg}
                alt="VayaPin"
                {...LOGO_SIZE}
              />
            </Link>
          )}
          <h1 className={`d-block d-md-none ${Styles.colMobileTitle}`}>
            {title}
          </h1>
          <ul className={`d-none d-md-flex text-end ${Styles.colDesktopNavigation}`}>
            <li>
              <Link href="https://www.vayapin.com">
                {t('common.ourWeb', 'Our web')}
              </Link>
            </li>
            <li>
              <Link href="/explore">
                {t('common.explore', 'Explore')}
              </Link>
            </li>
            <li>
              <Link href="/account" title={t('common.account', 'Account') || ''}>
                {
                  signedIn ? (
                    <MdAccountCircle className={Styles.iconAccount} />
                  ) : t('common.signin', 'Sign In')
                }
              </Link>
            </li>
          </ul>
          <div className={`${Styles.colIcon} ${Styles.colIconRight}`}>
            {!skipMenu && (
              <ButtonMenu />
            )}
          </div>
        </Container>
      </div>
      <div id="header-portal"></div>
    </header>
  )
}

export default NavigationBar
