'use client'

import 'client-only'

import { Result, Button } from 'antd'
import { useRouter } from 'next/navigation'

function ClientNotFoundContent() {
  const router = useRouter()

  return (
    <Result
      status="404"
      title="404"
      subTitle="Sorry, the page you visited does not exist."
      extra={
        <Button type="primary" onClick={() => { router.push('/') }}>
            Back Home
        </Button>
      }
    />
  )
}

export default ClientNotFoundContent
