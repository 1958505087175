import { JSX, useCallback } from 'react'
import { useRouter } from 'next/navigation'
import Script from 'next/script'
import Config from '~/util/Config'

const COOKIE_CONSENT_OPTIONS = {
  enabled: true,
  revokable: false,
  secure: true,
  autoOpen: true,
  cookie: {
    name: 'vpcc',
    domain: Config.NEXT_PUBLIC_COOKIE_DOMAIN,
    secure: Config.NEXT_PUBLIC_COOKIE_SECURE,
  },
  palette: {
    popup: {
      background: '#777',
      text: '#ffffff'
    },
    button: {
      background: '#0c6',
      text: '#ffffff'
    }
  },
  theme: 'classic',
  position: 'banner-bottom',
  type: 'opt-in',
  content: {
    header: 'We want to improve',
    message: `This page uses cookies to let us improve the usability and content of VayaPin.`,
    dismiss: 'Got it!',
    allow: 'Ok, continue',
    deny: 'decline',
    link: 'More',
    href: 'https://www.vayapin.com/en/privacy-notes/',
    policy: 'Cookies',
    target: '_blank'
  }
}

function CookieConsent(): JSX.Element {
  const router = useRouter()

  const onStatusChange = useCallback(() => {
    router.refresh()
  }, [router])

  const onLoad = useCallback(() => {
    // @ts-ignore
    COOKIE_CONSENT_OPTIONS.onStatusChange = onStatusChange

    // @ts-ignore
    window.cookieconsent.initialise(COOKIE_CONSENT_OPTIONS)
  }, [onStatusChange])

  return (
    <>
      {/* eslint-disable-next-line @next/next/no-sync-scripts */}
      <Script
        src="https://cdn.jsdelivr.net/npm/cookieconsent@3/build/cookieconsent.min.js"
        onLoad={onLoad}
      />
    </>
  )
}

export default CookieConsent
