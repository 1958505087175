import { isPresent } from 'lib/utils'
import Link from 'next/link'
import { useRouter } from 'next/navigation'
import { FC, memo, ReactNode, useCallback, useMemo } from 'react'
import { FaChevronLeft } from 'react-icons/fa'

import Styles from './ButtonBack.module.sass'

export interface ButtonBackProps {
  href?: string;
}

const ButtonBack: FC<ButtonBackProps> = ({
  href,
}) => {
  const router = useRouter()

  const url = useMemo(
    () => isPresent(href) ? { href } : { href: '/' },
    [href]
  )

  const onClick = useCallback(() => {
    if (new RegExp(document.location.host).test(document.referrer)) {
      router.back()
    } else {
      void router.push('/')
    }
  }, [router])

  return (
    <ButtonWrapper
      href={url?.href}
      onClick={onClick}
      className={Styles.button}
    >
      <FaChevronLeft />
    </ButtonWrapper>
  )
}

interface ButtonWrapperProps {
  href?: string;
  target?: string;
  onClick?: () => void;
  className?: string;
  children?: ReactNode;
}

const ButtonWrapper = memo<ButtonWrapperProps>(({
  href,
  target,
  children,
  onClick,
  ...restProps
}) => isPresent(href) ? (
  <Link href={href} target={target} {...restProps}>
    {children}
  </Link>
) : (
  <button onClick={onClick} {...restProps}>
    {children}
  </button>
))
ButtonWrapper.displayName = 'ButtonWrapper'

export default ButtonBack
