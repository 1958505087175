import { FC, useMemo } from 'react'
import { HiMenu } from 'react-icons/hi'
import { useDrawer } from 'services/NavigationDrawer'
import { NavigationContent } from 'organisms'
import classnames from 'classnames'
import Styles from './ButtonMenu.module.sass'

const ButtonMenu: FC<unknown> = () => {
  const { toggleDrawerOpen } = useDrawer()

  const classesBtn1 = useMemo(() => classnames({
    'd-flex': true,
    'd-md-none': true,
    [Styles.button]: true,
  }), [])

  const classesBtn2 = useMemo(() => classnames({
    'd-none': true,
    'd-md-flex': true,
    [Styles.button]: true,
    [Styles.buttonDropdown]: true
  }), [])

  return (
    <>
      <button
        className={classesBtn1}
        onClick={toggleDrawerOpen}
      >
        <HiMenu />
      </button>
      <button className={classesBtn2}>
        <HiMenu />
        <div className={Styles.dropdown}>
          <NavigationContent />
        </div>
      </button>
    </>
  )
}

export default ButtonMenu
