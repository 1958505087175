'use client'

import 'client-only'

import classnames from 'classnames'
import { useTranslation } from 'i18n/client'
import Link from 'next/link'
import { usePathname } from 'next/navigation'
import { FC, ReactNode, useMemo } from 'react'
import { FaHeart, FaHistory, FaMapMarkerAlt, FaSearch } from 'react-icons/fa'
import { NEXT_PUBLIC_STAGING, NODE_ENV } from '~/util/Config'

import Styles from './BottomTabBar.module.sass'

interface ItemProps {
  display?: boolean;
  href: string;
  icon: ReactNode;
  i18nKey: string;
  i18nFallback: string;
}

function Item({
  display = true,
  href,
  icon,
  i18nKey,
  i18nFallback,
}: ItemProps) {
  const { t } = useTranslation()
  const pathname = usePathname()

  const active = useMemo(
    () => pathname == href,
    [href, pathname]
  )

  const itemClasses = useMemo(() => classnames({
    [Styles.item]: true,
    [Styles.itemStaging]: NEXT_PUBLIC_STAGING || NODE_ENV == 'development',
    [Styles.itemActive]: active,
  }), [active])

  return display ? (
    <div className={itemClasses}>
      <Link href={href} className={`${Styles.link}`} >
        {icon}
        <div className={Styles.label}>{t(i18nKey, i18nFallback)}</div>
      </Link>
    </div>
  ) : null
}

const BottomTabBar: FC = () => (
  <div className={`${Styles.container}`}>
    <Item
      href="/"
      icon={<FaSearch className={Styles.icon} />}
      i18nKey="common.search"
      i18nFallback="Search"
    />
    <Item
      href="/history"
      icon={<FaHistory className={Styles.icon} />}
      i18nKey="common.history"
      i18nFallback="History"
      display={NEXT_PUBLIC_STAGING || NODE_ENV == 'development'}
    />
    <Item
      href="/favourites"
      icon={<FaHeart className={Styles.icon} />}
      i18nKey="common.favourites"
      i18nFallback="Favourites"
    />
    <Item
      href="/account"
      icon={<FaMapMarkerAlt className={Styles.icon} />}
      i18nKey="common.myPins"
      i18nFallback="MyPins"
    />
  </div>
)

export default BottomTabBar
